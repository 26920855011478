@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap');

* {
  font-family: Quicksand !important;
}

#hover:hover {
  cursor: pointer;
}

/* Chrome, Safari ve Opera için */
::-webkit-scrollbar {
  width: 9px;
  /* scrollbar'ın genişliği */
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #333d48c0;
  /* scrollbar'ın rengi */
  border-radius: 5px;
  /* scrollbar'ın border radius'ı */
  opacity: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #333d48c0;
  /* scrollbar'a hover olduğunda renk */
  opacity: 0;
}

.shadow {
  box-shadow: 0 2px 15px rgb(0 0 0 / 68%);
}

/* Chat List */
.ce-wrapper {
  background-color: #212b36 !important;
}

.ce-chats-container {
  background-color: #212b36 !important;
}

.ce-chat-form-container {
  background-color: #212b36 !important;
  color: white;
}

.ce-chat-card {
  margin: 0px 6px !important;
}

.ce-chat-card-loading-bar {
  background-color: #535a6d !important;
}

.ce-active-chat-card {
  background-color: #36b37e !important;
}

.ce-chat-title-text {
  color: white !important;
}

.ce-chat-subtitle-text {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-chat-unread-dot {
  background-color: white !important;
}

.ce-chat-list {
  background-color: #212b36 !important;
}

/* Chat Feed */

.ce-chat-title-container {
  background-color: #212b36 !important;
  border-radius: 0px !important;
}

.ce-chat-feed-container {
  background-color: #212b36 !important;
}

.ce-primary-button {
  background-color: #00ab55 !important;
}

.ce-message-date-text {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-my-message-bubble {
  background-color: #36b37e !important;
}

.ce-my-message-sinding-bubble {
  background-color: #00ab55 !important;
}

.ce-my-message-timestamp {
  color: #00ab55 !important;
}

.ce-their-message-timestamp {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

#ce-send-message-button {
  background-color: #00ab55 !important;
}

.ce-ice-breaker-gif {
  display: none;
}

#ce-ice-breaker-gif {
  display: none;
}

.ce-social-message-form {
  background-color: #212b36 !important;
}

.ce-message-input-form {
  padding-bottom: 25px !important;
}

#msg-textarea {
  width: calc(100% - 140px) !important;
  position: relative !important;
  top: 4px !important;
  border-width: 0px !important;
  background-color: #212b36 !important;
  color: white !important;
}

#msg-textarea::placeholder {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

#ce-upload-document-icon {
  color: white !important;
}

.ce-feed-container-bottom {
  height: '44px';
}

.ce-message-images-row {
  background-color: #212b36 !important;
}

.ce-their-message-sender {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-their-message-bubble {
  background-color: #535a6d !important;
  color: rgba(255, 255, 255, 0.705) !important;
}

/* Chat Settings */

.ce-settings {
  background-color: #212b36 !important;
  border-radius: 0px !important;
}

.ce-chat-title-form .ce-text-input {
  background-color: #212b36;
  color: white;
}

.ce-section-title-container {
  background-color: #212b36 !important;
  color: white;
}

#ce-options-drop-down {
  background-color: #212b36 !important;
  color: white;
}

.ce-person-text {
  color: hsla(0, 0%, 100%, 0.568);
}

.ce-danger-button {
  background-color: #212b36 !important;
  border: 1px solid #00ab55 !important;
  color: #00ab55 !important;
}

.ce-autocomplete-close {
  background-color: white !important;
  border-radius: 0px !important;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.carousel-link {
  text-decoration: none;
}
